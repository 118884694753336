var $name="scrollToActiveElement",$path="app/utilities/scrollToActiveElement.js",$this={$name,$path,};import getTotalStickyHeight from 'app/utilities/getTotalStickyHeight';
import { keyNames } from 'configs';
import delay from 'lodash/delay';

export default function scrollToActiveElement(event) {
  const { code } = event;

  if (code === keyNames.tab) {
    delay(() => {
      const totalStickyHeight = getTotalStickyHeight();
      const maxVisibleHeight = window.innerHeight - totalStickyHeight;
      const focusedElementViewportStartDiff = document.activeElement.getBoundingClientRect().top;
      if (
        (document.activeElement.offsetHeight > maxVisibleHeight)
        || (focusedElementViewportStartDiff < totalStickyHeight)
      ) {
        window.scrollTo({
          top: window.scrollY - totalStickyHeight + focusedElementViewportStartDiff,
        });
      }
    });
  }
}
