var $class="se2--container-tab",$name="ContainerTab",$path="app/components/ContainerTab/index.js",$this={$class,$name,$path,};import 'app/components/ContainerAccordion';
import 'app/components/ContentArticle';
import 'app/components/ContentArticleHighlights';
import 'app/components/ContentArticleSlideCarousel';
import 'app/components/ContentAssetBlocks';
import 'app/components/ContentFlexibleBlocks';
import 'app/components/ContentListBlocks';
import 'app/components/ContentTable';
import 'app/components/ContentProductBlocks';

import { tabsClassName } from 'app/components/NavigationBarCustomerJourneyJumpTo/config';
import onTabArrowClick, {
  arrowWidth,
  nextButtonClassName,
  prevButtonClassName,
} from 'app/components/NavigationCustomerJourney/utilities/onTabArrowClick';
import toggleArrowsVisibility from 'app/components/utilities/toggleArrowsVisibility';

import shell from 'app/modules/shell';

import isUsingKeyboard from 'app/utilities/isUsingKeyboard';
import parseDecimal from 'app/utilities/parseDecimal';
import scrollToActiveElement from 'app/utilities/scrollToActiveElement';
import setTabIndex from 'app/utilities/setTabIndex';

import { css, delays, directions, keyNames } from 'configs';

import find from 'lodash/find';
import first from 'lodash/first';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import throttle from 'lodash/throttle';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import itemClassName from './partials/item';

export const componentClassName = $class;
export const contentClassName = 'tab-content';
export const nestedContentClassName = 'nested-components';
export const ariaSelectedAttribute = 'aria-selected';
export { itemClassName };

const toggleActiveElements = (element, status) => {
  if (!element) return;

  element.setAttribute('aria-expanded', status);
  setTabIndex(element, status);
  element.classList.toggle(css.classNames.invisible);
};

const hideTabWhenEmpty = (element) => {
  const tabElements = element.querySelectorAll(`.${contentClassName} > .${nestedContentClassName}`);
  forEach(tabElements, (tabElement) => {
    if (tabElement.scrollHeight === 0) {
      const { index } = tabElement.closest(`.${contentClassName}`).dataset;
      const tabButton = element.querySelector(`.${itemClassName}[data-index="${index}"]`);
      tabButton.classList.add(css.classNames.hide);
    }
  });
};

const setActiveTab = ({ target }) => {
  if (!target.classList.contains(itemClassName)) return;

  const tabsElement = target.closest(`.${$class}`);
  const selectedTabIndex = parseDecimal(target.dataset.index);
  const selectedControl = tabsElement.querySelector(`.${itemClassName}[aria-selected="true"]`);

  selectedControl?.setAttribute(ariaSelectedAttribute, 'false');
  setTabIndex(selectedControl, false);
  target.setAttribute(ariaSelectedAttribute, 'true');
  setTabIndex(target, true);
  target.focus();

  toggleActiveElements(tabsElement?.querySelector(`.${contentClassName}[aria-expanded="true"]`), false);
  toggleActiveElements(tabsElement?.querySelector(`.${contentClassName}[data-index="${selectedTabIndex}"]`), true);
};

const onKeyboardEvent = (event, element) => {
  const { code } = event;
  const tabControls = [...element.querySelectorAll(`.${itemClassName}`)];
  const currentSelectedTab = find(tabControls,
    (tab) => tab.getAttribute(ariaSelectedAttribute) === 'true');

  if (isUsingKeyboard() && document.activeElement.classList.contains(itemClassName)) {
    if (code === keyNames.arrowLeft) {
      const target = currentSelectedTab.previousElementSibling;
      target && setActiveTab({ target });
    }

    if (code === keyNames.arrowRight) {
      const target = currentSelectedTab.nextElementSibling;
      target && setActiveTab({ target });
    }

    if (code === keyNames.home) {
      event.preventDefault();
      const target = first(tabControls);
      setActiveTab({ target });
    }

    if (code === keyNames.end) {
      event.preventDefault();
      const target = last(tabControls);
      setActiveTab({ target });
    }
  }
  scrollToActiveElement(event);
};

export default shell.registerComponent($this, ({ addEventListener, mount, settings }) => {
  mount((element) => {
    const scrollingElement = element.querySelector(`.${tabsClassName}`);
    const prevButton = element.querySelector(`.${prevButtonClassName}`);
    const nextButton = element.querySelector(`.${nextButtonClassName}`);
    const tabs = element.querySelectorAll(`.${contentClassName}`);
    const tabControls = [...element.querySelectorAll(`.${itemClassName}`)];
    const currentSelectedTab = find(tabControls,
      (tab) => tab.getAttribute(ariaSelectedAttribute) === 'true') || tabControls[0];

    forEach(tabs, (tab) => {
      currentSelectedTab?.getAttribute('data-index') !== tab.getAttribute('data-index')
        && toggleActiveElements(tab, false);
    });
    hideTabWhenEmpty(element);
    addEventListener(element, 'click', setActiveTab);
    addEventListener(element, 'keydown', (event) => onKeyboardEvent(event, element));
    addEventListener(prevButton, 'click', (event) => onTabArrowClick(event, scrollingElement, itemClassName));
    addEventListener(nextButton, 'click', (event) => onTabArrowClick(event, scrollingElement, itemClassName));
    addEventListener(
      scrollingElement,
      'scroll',
      () => toggleArrowsVisibility(scrollingElement, prevButton, nextButton),
    );

    const scrollToActiveTab = () => {
      if (isMobileBreakpoint()) {
        const scrollValue = currentSelectedTab?.offsetLeft - arrowWidth;
        if ((scrollValue + currentSelectedTab?.offsetWidth) >= window.innerWidth) {
          scrollingElement.scroll({ left: settings.direction === directions.rtl ? -(scrollValue) : scrollValue });
        }
      }
    };

    scrollToActiveTab();
    // Buttons initialization
    toggleArrowsVisibility(
      scrollingElement,
      prevButton,
      nextButton,
    );

    addEventListener(
      window,
      'resize',
      throttle(
        () => {
          toggleArrowsVisibility(scrollingElement, prevButton, nextButton);
          scrollToActiveTab();
        },
        delays.ms300,
      ),
    );
  });
});
