var $class="se2--content-asset-blocks",$name="ContentAssetBlocks",$path="app/components/ContentAssetBlocks/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import shell from 'app/modules/shell';

import 'app/partials/image';
import 'app/partials/uberflipCard';

import initializeSlider from 'app/components/utilities/initializeSlider';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const { onResize } = initializeSlider(element, addEventListener);

    initializeSlider(element, addEventListener);

    addEventListener(window, 'resize', onResize);

    lazyHandleOpenModalButtons(addEventListener, element);
  });
});
