var $class="se2--table",$name="table",$path="app/partials/table/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import toBoolean from 'app/utilities/toBoolean';

import { sortDirections } from 'configs';

import every from 'lodash/every';
import forEach from 'lodash/forEach';
import identity from 'lodash/identity';

const sortingClassName = 'sorting';
const sortingSelector = `.${sortingClassName}`;
const tableSelector = '.table';
const ariaLabelAttrName = 'aria-label';
const stringType = 'string';
const numberType = 'number';

// order in table starts form 1, not from 0
const getOrderColumn = ({ dataset: { orderColumn } }) => (orderColumn ? +orderColumn - 1 : 0);

const checkIfDate = (text) => {
  const date = new Date(text);
  return isNaN(date)
    ? text
    : date;
};

const getCellInnerText = (row, columnIndex) => checkIfDate(row.cells[columnIndex].innerText);

const getColumnDataType = (trElements, columnIndex) => (
  every(trElements, (trElement) => Number.isFinite(getCellInnerText(trElement, columnIndex)))
    ? numberType
    : stringType
);

const setSortingDirection = (columnDirection, thElement, tableElement) => {
  const button = thElement.querySelector('button');
  const buttons = tableElement?.tHead?.querySelectorAll('button');
  const innerText = button?.innerText;
  const { ascLabel, descLabel, defaultLabel } = tableElement.dataset;

  forEach(
    buttons,
    (element) => element.setAttribute(ariaLabelAttrName, `${defaultLabel} ${element.innerText}`),
  );

  thElement.dataset.orderDirection = (columnDirection === sortDirections.asc)
    ? sortDirections.desc
    : sortDirections.asc;

  button?.setAttribute(
    ariaLabelAttrName,
    columnDirection === sortDirections.asc ? `${ascLabel} ${innerText}` : `${descLabel} ${innerText}`,
  );
};

const types = {
  number: 'number',
  string: 'string',
};

const converters = {
  [types.number]: parseFloat,
  [types.string]: identity,
};

const compare = (a, b) => (
  // eslint-disable-next-line no-nested-ternary
  a > b ? 1 : a < b ? -1 : 0
);

const convert = (type, value) => converters[type](value);

const sortRows = (trElements, columnIndex, type, direction) => trElements.sort(
  (row1, row2) => direction * compare(
    convert(type, getCellInnerText(row1, columnIndex)),
    convert(type, getCellInnerText(row2, columnIndex)),
  ),
);

const sortTable = (element) => {
  const hasTableClass = element.classList.contains('table');
  const tableElement = hasTableClass ? element : element.closest('.table');
  const trElements = Array.from(tableElement.querySelectorAll('tbody tr'));
  const thElement = hasTableClass ? tableElement.querySelectorAll('th')[getOrderColumn(tableElement)] : element;

  if (thElement) {
    const orderColumnIndex = thElement.cellIndex ?? 0;

    const typeOfData = getColumnDataType(trElements, orderColumnIndex);
    const { orderDirection } = thElement.dataset;
    const direction = (orderDirection === sortDirections.asc) ? 1 : -1;
    setSortingDirection(orderDirection, thElement, tableElement);
    tableElement.tBodies[0].append(...sortRows(trElements, orderColumnIndex, typeOfData, direction));
  }
};

const setSortArrowState = (table, th) => {
  const currentTh = th ?? table.querySelectorAll('th')[getOrderColumn(table)];

  if (currentTh) {
    const { classList } = currentTh;
    const prevSortingElement = table?.querySelector(sortingSelector);
    prevSortingElement?.classList.remove(sortingClassName, sortDirections.asc, sortDirections.desc);

    switch (classList) {
      case classList.contains(sortDirections.asc):
        currentTh.classList.remove(sortDirections.asc);
        currentTh.classList.add(sortDirections.desc, sortingClassName);
        break;
      case classList.contains(sortDirections.desc):
        currentTh.classList.add(sortDirections.asc, sortingClassName);
        currentTh.classList.remove(sortDirections.desc);
        break;
      default:
        currentTh.classList.add(currentTh.dataset.orderDirection, sortingClassName);
    }
  }
};

const onTableColumnHeadingClick = ({ currentTarget }) => {
  const table = currentTarget.closest(tableSelector);
  setSortArrowState(table, currentTarget);
  sortTable(currentTarget);
};

const enableOrdering = (table, addEventListener) => {
  getOrderColumn(table);
  setSortArrowState(table);
  sortTable(table);
  forEach(
    table.querySelectorAll('th'),
    (elem) => addEventListener(elem, 'click', onTableColumnHeadingClick),
  );
};

export default shell.registerComponent($this, ({ addEventListener, mount, subscribeToMessage, configs }) => {
  mount((element) => {
    const table = element.querySelector('.table');

    if (table && toBoolean(table.dataset?.enableOrdering)) {
      enableOrdering(table, addEventListener);
    }

    subscribeToMessage(configs.messages.sortTableData,
      (dataContentId) => {
        const targetTable = document.querySelector(`[data-content-id='${dataContentId}'] .table`);

        if (targetTable && toBoolean(targetTable.dataset?.enableOrdering)) {
          enableOrdering(targetTable, addEventListener);
        }
      });
  });
});
