var $class="se2--content-article",$name="ContentArticle",$path="app/components/ContentArticle/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';
import shell from 'app/modules/shell';

import 'app/partials/image';

import setYoutubeVideoThumbnail from 'app/utilities/setYoutubeVideoThumbnail';

export default shell.registerComponent($this, ({ addEventListener, configs, mount }) => {
  mount((element) => {
    const { videoThumbnail } = configs.css.classNames;
    setYoutubeVideoThumbnail(element, videoThumbnail);
    lazyHandleOpenModalButtons(addEventListener, element, [videoThumbnail]);
  });
});
